<template>
  <v-container style="height: 100%" fluid>
    <v-snackbar color="green" top v-model="snackbar" :timeout="timeout" content-class="text--center">
      <span class="d-flex justify-center">
        {{ text }}
      </span>
    </v-snackbar>
    <v-snackbar color="error" top v-model="snackbarError" :timeout="timeout" content-class="text--center">
      <span class="d-flex justify-center">
        {{ textError }}
      </span>
    </v-snackbar>
    <v-container>
      <h1>Cadastro de ponto</h1>
      <v-row class="mt-2">
        <v-col cols="12">
          <div>
            <label class="v-file-input-label" style="display: flex;justify-content: center;">
              <div class="camera-circle">
                <v-icon color="white">
                  mdi-camera
                </v-icon>
              </div>
              <input type="file" ref="fileInput" @change="handleImageUpload" class="v-file-input-hidden">
            </label>
            <div v-for="(image, index) in imagePreviews" :key="index" class="responsive-image-container mt-2">
              <img :src="image" alt="Uploaded Image" class="responsive-image">
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-4">
        <v-col>
          <v-select v-model="categoriaSelecionada" outlined color="blue" no-data-text="Nenhuma categoria cadastrada"
            label="Categoria" :items="tabela.categorias" hide-details />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select :disabled="!categoriaSelecionada" v-model="elementoSelecionado" outlined color="blue"
            label="Elementos" :items="getElementos" hide-details />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select v-model="impactosSelecionados" outlined multiple color="blue" label="Impactos"
            :disabled="!elementoSelecionado" :items="tabela.impactos" hide-details />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="relato" label="Relato" outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" justify="center">
          <v-btn :loading="isLoading" :disabled="isLoading" @click="create()" color="primary" x-large style="width: 100%">
            <span style="color: white">
              {{ "CADASTRAR" }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import tabela from '@/mixin.js'
import loadImage from 'blueimp-load-image';

export default {
  name: "CriarPonto",
  mixins: [tabela],
  computed: {
    getElementos() {
      switch (this.categoriaSelecionada) {
        case this.tabela.categorias[0]:
          return this.tabela.elementosAreas
        case this.tabela.categorias[1]:
          return this.tabela.elementosRotas
        case this.tabela.categorias[2]:
          return this.tabela.elementosEstruturas
        case this.tabela.categorias[3]:
          return this.tabela.elementosMancha
        case "Outro":
          return [""];
        default:
          return ["erro", "erro"];
      }
    },
  },
  data() {
    return {
      imagePreviews: [],
      relato: "",
      selectedFile: null,
      selectedFile2: null,
      relatoRules: [
        (v) =>
          (v && v.length <= 400) ||
          "Relato deve conter no máximo 400 caracteres",
      ],
      categoriaSelecionada: "",
      elementoSelecionado: "",
      impactosSelecionados: [],
      file: [],
      coordinatesMarker: [],
      snackbar: false,
      snackbarError: false,
      text: "Ponto cadastrado com sucesso!",
      textError: "Erro ao cadastrar denúncia",
      timeout: 2000,
      isLoading: false,
      eagerTransformation: 'eager-rotate',
    };
  },
  mounted() {
    const data = this.$route.query.data;

    if (data) {
      // Handle the navigation and use the data object as needed
      // Example: Parse the JSON string back to an object and perform actions
      this.coordinatesMarker = JSON.parse(data);
      // ...
    }
  },
  watch: {
    file(newV, oldV) {
      if (newV[0]?.name !== oldV[0]?.name && oldV.length)
        this.file.push(...oldV);
    },
  },
  methods: {
    handleImageUpload() {
      const fileInput = this.$refs.fileInput;
      const files = fileInput.files;
      if (files && files.length > 0) {
        // Clear previous previews
        this.imagePreviews = [];

        // Iterate over the selected files and process each one
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          loadImage(
            file,
            (canvas) => {
              // Convert canvas to a data URL and add it to the previews array
              const correctedDataURL = canvas.toDataURL('image/jpeg');
              this.imagePreviews.push(correctedDataURL);
            },
            { orientation: true, canvas: true }
          );
        }
      }
    },
    getUserId() {
      return JSON.parse(localStorage.getItem("userData")).id;
    },
    deleteChip(index) {
      this.file.splice(index, 1);
    },
    async create() {
      this.isLoading = true;

      // Send the FormData to your Strapi server

      const formData = new FormData();

      let lng = this.coordinatesMarker[1].toString();
      let lat = this.coordinatesMarker[0].toString();

      let obj = {
        lat: lat,
        lng: lng,
        categories: this.categoriaSelecionada,
        impacts: this.impactosSelecionados.join(";"),
        publicado: false,
        elements: this.elementoSelecionado,
        users_permissions_user: {
          connect: [{ id: this.getUserId() }],
        },
        relato: this.relato,
      };



      formData.append("data", JSON.stringify(obj));

      const fileInput = this.$refs.fileInput;
      const files = fileInput.files;
      formData.append("files.photo", files[0]);



      // TODO ADD ERROR HANDLING
      let resp = await fetch(process.env.VUE_APP_BASE_URL + "denuncias"
        ,
        {
          method: "POST",
          body: formData,
          headers: {
            ...(localStorage.getItem("token") && {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }),
          },
        }
      );
      if (resp.status == 200) {
        this.isLoading = false;
        this.snackbar = true;
        setTimeout(() => this.$router.push("/app/campo"), 1500);
      } else {
        this.isLoading = false;
        this.snackbarError = true
      }
    }
  },
};
</script>
<style scoped>
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  /* Adjust the gap between images as needed */
}

.responsive-image-container {
  max-width: 100%;
  /* Limit the container's width */
  text-align: center;
}

.responsive-image {
  max-width: 100%;
  /* Limit the image's width */
  height: auto;
  /* Automatically adjust the height to maintain aspect ratio */
}

.v-file-input-label {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Adjust the gap as needed */
  cursor: pointer;
}

.v-file-input-hidden {
  display: none;
}

.v-file-input-button {
  color: #1976d2;
  /* Change the button color as needed */
  font-size: 14px;
  /* Adjust the font size as needed */
}

.v-text-field--:not(.v-text-field--single-line) input {
  margin-top: 0px !important;
}

.camera-circle {
  background-color: #1976d2;
  border-radius: 25px;
  padding: 45px;
}

.snapshot-mapa {
  height: 50%;
}
</style>